import React, { useState, useEffect } from "react";
import "./App.css";
import { ReactComponent as Logo } from "./map-pin-svgrepo-com.svg";

function App() {
  const [sendingLocation, setSendingLocation] = useState(false);
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [selectedUUID, setSelectedUUID] = useState(null);
  const assetUUIDs = {
    Rob: "654a3dd8-4c2f-4578-b5c9-9642f46c29b8",
    Phil: "99c06992-e3ae-4bd2-b6ef-68094d2f3a76",
    Cameron: "550b239b-5c67-4db0-95a7-9601f7d03222",
  };

  const toggleAsset = (asset) => {
    setSendingLocation(false);

    if (selectedAsset === asset) {
      setSelectedAsset(null);
      setSelectedUUID(null);
    } else {
      setSelectedAsset(asset);
      setSelectedUUID(assetUUIDs[asset]);
    }
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) =>
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          }),
        (error) => console.error(error),
        { enableHighAccuracy: true, timeout: 10000 }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  const stopGettingLocation = () => {
    toggleAsset(null);
    setLocation({ latitude: null, longitude: null });
    setSendingLocation(false);
  };

  useEffect(() => {
    const interval = setInterval(getLocation, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    let isFetching = true;
    const { latitude, longitude } = location;

    const fetchApiData = () => {
      if (!isFetching || !selectedUUID || !latitude || !longitude) return;

      fetch("https://locator-api.dev1.livingmap.com/v1/ping", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer 19af612746c46157f9ffc343bbb68585",
        },
        body: JSON.stringify({
          assetUUID: selectedUUID,
          position: {
            longitude: location.longitude,
            latitude: location.latitude,
          },
          floorId: "1",
        }),
      })
        .then(() => console.log(`Asset pinged at [${latitude}, ${longitude}]`))
        .catch((error) => console.error("Error pinging asset:", error));
    };

    if (selectedUUID && sendingLocation) {
      fetchApiData();
    }

    return () => (isFetching = false);
  }, [selectedUUID, location, sendingLocation]); // Now depends on both selectedUUID and location

  return (
    <div className="App">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h2>Living Map Locator</h2>
        <Logo width="50" height="50" style={{ marginLeft: "10px" }} />
      </div>

      <p>Select an Asset</p>
      <div className="button-row">
        {Object.keys(assetUUIDs).map((asset) => (
          <button
            key={asset}
            onClick={() => toggleAsset(asset)}
            style={{
              backgroundColor: selectedAsset === asset ? "green" : null,
              marginRight: "10px",
            }}
          >
            {asset}
          </button>
        ))}
      </div>

      <div className="button-row">
        <button
          onClick={() => setSendingLocation(true)}
          disabled={sendingLocation}
          style={{ opacity: sendingLocation ? 0.5 : 1, marginRight: "10px" }}
        >
          Start Pinging
        </button>
        <button onClick={stopGettingLocation} disabled={!sendingLocation}>
          Stop Pinging
        </button>
      </div>
      {location.latitude && location.longitude && (
        <span>
          <p>Latitude: {location.latitude}</p>
          <p>Longitude: {location.longitude}</p>
        </span>
      )}
    </div>
  );
}

export default App;
